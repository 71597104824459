import { defineStore } from 'pinia'
import {User, Configuration, Strategy, BuybackStrategy, Extra} from "../types";
import {useStorage} from "@vueuse/core";
import {i18n} from "../i18n";

export const useUserStore = defineStore('user', {

    state: () => ({
        user: useStorage('user', null as string | null),
    }),

    getters: {
        check(): boolean {
            return this.profile !== null;
        },

        profile(): User | null {
            if (this.user === null) {
                return null;
            }
            const user = JSON.parse(this.user) as User;
            i18n.global.locale.value = user.language || "it-IT"

            return user;
        },

        configuration(): Configuration | null {
            if (this.profile === null) {
                return null;
            }

            return this.profile.configuration;
        },

        extra(): Extra | null {
            if (this.profile === null) {
                return null;
            }

            return this.profile.extra;
        },

        strategies(): Array<Strategy> {
            if (this.profile === null) {
                return [];
            }

            return this.profile.strategies;
        },

        buybackStrategies(): Array<BuybackStrategy> {
            if (this.profile === null) {
                return [];
            }

            return this.profile.buyback_strategies;
        },

        isMultiCountry(): boolean {
            if (this.extra === null) {
                return true;
            }

            if (this.configuration?.catalog_type === 'resell') {
                return this.extra?.resell_countries.length > 1;
            }

            return this.extra?.buyback_countries.length > 1;
        }
    },
    actions: {
        login(user: User) {
            this.user = JSON.stringify(user);
        },

        logout() {
            this.user = null;
        },

        updateStrategies(strategies: Array<Strategy>) {
            const user = this.profile;

            if (user === null) {
                return;
            }

            user.strategies = strategies;
            this.user = JSON.stringify(user);
        },

        updateBuybackStrategies(strategies: Array<BuybackStrategy>) {
            const user = this.profile;

            if (user === null) {
                return;
            }

            user.buyback_strategies = strategies;
            this.user = JSON.stringify(user);
        }
    },
})
